.shortAddress {
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  align-items: center;
}

.shortAddress > div {
  padding-top: -2px;
}

.shortAddress span {
  letter-spacing: 0.2px;
  font-family: 'PT Root UI';
  font-weight: bold;
}

.nounImg {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.nounImg:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.nounWrapper {
  margin-bottom: 1rem;
}

.currentBidCol {
	/*text-align: center;*/
}

.currentBidCol h3 {
  font-size: 1.65rem;
}

.whiteInfo {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgb(95, 95, 95);
}

.whiteInfo:hover {
  background-color: #e2e3e8;
  color: black;
}

.statsWrapper {
  text-align: center;
}

.notificationsWrapper {
	
}

@media (max-width: 992px) {
  .footerSignature a {
    font-size: 16px;
  }
  .statsWrapper {
  	text-align: right;
  	padding-right: calc(var(--bs-gutter-x) * .5);	
  }  
  .notificationsWrapper {
  	text-align: center;	
  }  
}

