.bannerSection {
  background: #434e43;
  color: #a3efd0;
}

.wrapper h1 {
  font-family: 'Londrina Solid';
  font-size: 4rem;
}

@media (max-width: 992px) {
  .wrapper {
    padding: 2rem;
  }

  .wrapper h1 {
    font-size: 3.75rem;
  }
}

@media (min-width: 992px) {
  .wrapper h1 {
    font-size: 5rem;
    margin-left: 2rem;
  }
}
