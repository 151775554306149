.section span {
  font-size: 3rem;
  font-weight: bold;
  font-family: 'Londrina Solid';
}

.section h4 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;  
}

.section h2 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 0px !important;
  margin-top: 3px;
}

.wrapper {
  margin-left: 0.5rem;
  margin-top: 2px;
  padding-left: 1.5rem;
}

@media (max-width: 992px) {
  .section h4 {
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 6px;
  }

  .section h2 {
    font-size: 23px;
  }

  .section {
    justify-content: space-between;
  }

  .currentBid {
    margin-right: 0.5rem;
  }

  .wrapper {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  	padding-left: 0;    
  }

  .leftCol {
    padding-left: 0.5rem;
  }
}
