@import './css/colors.css';

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  background: #d5d7e0;
  /*
  background: #434e43;
  color: #a3efd0;
  */
}
