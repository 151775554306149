.mobileOnly {
  display: none;
}

@media (max-width: 1200px) {
  .desktopOnly {
    display: none;
  }

  .mobileOnly {
    display: inherit;
  }
}

@media (max-width: 414px) {
  .disableSmallScreens {
    display: none;
  }
}
