.proposalEditor {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem 1rem 1rem;
  border: 1px solid #aaa !important;
  border-radius: 0.25rem !important;
  outline: none !important;
  box-shadow: none !important;
}

.titleInput,
.bodyInput {
  padding: 0;
  border: none;
  width: 100% !important;
  outline: none !important;
  box-shadow: none !important;
}

.titleInput {
  font-size: 1.25rem;
}

.bodyInput {
  min-height: 340px !important;
}

.divider {
  width: 100%;
  margin: 0 0 0.5rem 0;
}

.previewArea {
  padding: 0.5rem 1rem 1rem 1rem;
  border: 1px solid #aaa !important;
  border-radius: 0.25rem !important;
  outline: none !important;
  box-shadow: none !important;
}

.markdown h1 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h3 {
  font-size: 1.3rem;
}

.markdown img {
  max-width: 100%;
  height: auto;
}
